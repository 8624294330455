import React from 'react';
import SelectedProducts from '../components/selectedProducts';

const SelectedProductsPage = () => {

    return(
        <div>
            <SelectedProducts />
        </div>
    )
}
export default SelectedProductsPage;